<template>
  <div>
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
      <div class="container">
        <div class="breadcurmb-title text-center">
          <h1>ACTUALITES</h1>
        </div>
        <div class="breadcurmb-item-list text-center ul-li">
          <ul class="saasio-page-breadcurmb">
            <li><router-link to="/">ACCUEIL</router-link></li>
            <li><a href="#"> ACTUALITES</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div v-for="item in items" :key="item.id" class="col-md-6">
              <div class="blog-feed-post">
                <div class="blog-feed-img-txt">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div style="height: 250px;" class="saasio-blog-img">
                        <router-link :to="item.link" >
                          <img style="height: 100% !important;object-fit: cover;" :src="item.image" :alt="item.title" />
                        </router-link>
                      </div>
                    </div>
                    <div style="padding-bottom: 10px;" class="col-lg-6 col-md-12 col-sm-12">
                      <div class="saasio-blog-text">
                        <h4>
                          <router-link :to="item.link" style="text-decoration: underline;" > {{ item.title }}</router-link>
                        </h4>
                        <p>
                          {{ item.except }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End of blog section
        ============================================= -->
  </div>
</template>

<script>
export default {
  name: "Blog",
  data() {
    return {
      items: [
      { 
        id: 1,
        title: "C'est quoi un logiciel CRM ?", 
        except: "Un logiciel CRM (Customer Relationship Management) est un outil informatique utilisé pour gérer les interactions.",
        image: "assets/images/blogs/crm9.PNG",
        link: '/C-est-quoi-un-logiciel-CRM'
      },
      {
        id: 2,
        title: "Comment il peut me servire ce logiciel CRM ?",
        except: "Un logiciel CRM peut vous être utile de plusieurs façons, notamment :",
        image: "assets/images/blogs/crm3.PNG",
        link: '/Comment-il-peut-me-servire-ce-logiciel-CRM'
      },
      {
        id: 3,
        title: "Logiciel crm pour gerer les factures et les ventes ?",
        except: "Un logiciel CRM (Customer Relationship Management) est un outil informatique utilisé pour gérer les interactions.",
        image: "assets/images/blogs/crm1.PNG",
        link: '/Logiciel-crm-pour-gerer-les-factures-et-les-ventes'
      },
      {
        id: 4,
        title: "Regelements des client et fournisseurs ?",
        except: "La gestion des règlements clients et fournisseurs peut également faire partie des fonctionnalités d'un logiciel CRM.",
        image: "assets/images/blogs/crm4.PNG",
        link: '/Regelements-des-client-et-fournisseurs'
      },
      {
        id: 5,
        title: "Un logiciel CRM et statistiques ?",
        except: "Un logiciel CRM peut également offrir des fonctionnalités de statistiques",
        image: "assets/images/blogs/crm2.PNG",
        link: '/Un-logiciel-CRM-et-statistiques'
      },
      {
        id: 6,
        title: "Comment Un logiciel CRM peut facilité mon travail ?",
        except: "Un logiciel CRM (Customer Relationship Management) peut faciliter votre travail",
        image: "assets/images/blogs/crm7.PNG",
        link: '/Comment-Un-logiciel-CRM-peut-facilité-mon-travail'
      }
    ]
    }    
  }
};
</script>

<style>
</style>